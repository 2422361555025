import { BrowserTracing } from "@sentry/browser";
import * as Sentry from "@sentry/react";
import DisplayAlertBar from "DisplayAlertBar";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { combineReducers, createStore } from "redux";
import SessionReducer from "redux/SessionReducer";
import App from "./App";
import "./FontAwesome";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import React from "react";

//#region Redux Reducer
const reducer = combineReducers({
    session: SessionReducer,
});

export type RootState = ReturnType<typeof reducer>;

export const store = createStore(reducer);

//#endregion

//#region Sentry
const sentryDsn = import.meta.env.VITE_SENTRY_DSN;
if (sentryDsn) {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 0.01,
        release: import.meta.env.VITE_VERSION,
        ignoreErrors: [/Failed to fetch dynamically imported module/],
    });
}

//#endregion

const handleError = (error: Error) => {
    if (
        error.toString().includes("Failed to fetch dynamically imported module") ||
        error.toString().includes("Failed to load module script")
    ) {
        // eslint-disable-next-line no-self-assign
        window.location = window.location;
    }
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <Sentry.ErrorBoundary onError={handleError}>
        <React.StrictMode>
            <Provider store={store}>
                <div id="alertheader" />
                <App />
            </Provider>
        </React.StrictMode>
    </Sentry.ErrorBoundary>
);

serviceWorkerRegistration.register({
    onUpdate: DisplayAlertBar,
});

declare global {
    interface Window {
        Trengo: any;
    }
}
