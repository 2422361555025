export const getIntArrayFromParams = (params: URLSearchParams, key: string): number[] => {
    const item = params.get(key);

    if (!item) {
        return undefined;
    }

    return item.split(",").map(function (i) {
        return parseInt(i);
    });
};

export const getStringArrayFromParams = (params: URLSearchParams, key: string): string[] => {
    const item = params.get(key);

    if (!item) {
        return undefined;
    }

    return item.split(",");
};

export const getUrlParams = (obj: unknown): URLSearchParams => {
    const params = new URLSearchParams();

    Object.keys(obj).forEach(k => {
        if (obj[k] !== undefined && obj[k] !== "" && obj[k].length !== 0) {
            params.set(k, obj[k]);
        }
    });

    return params;
};

export const tryGetParam = (params: URLSearchParams, key: string) => {
    const value = params.get(key);

    if (!value) {
        return undefined;
    }
    return value;
};
export const tryGetIntParam = (params: URLSearchParams, key: string) => {
    const value = params.get(key);

    if (!value) {
        return -1;
    }
    return value;
};

export const paramsToObject = (params: URLSearchParams) => {
    return Object.fromEntries(params);
};

const ParameterUtils = {
    getIntArrayFromParams,
    getStringArrayFromParams,
    getUrlParams,
    tryGetParam,
    tryGetIntParam,
    paramsToObject,
};

export default ParameterUtils;
