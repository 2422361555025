import { dom, library } from "@fortawesome/fontawesome-svg-core";
import {
    faArchive,
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faArrowCircleLeft,
    faArrowCircleRight,
    faArrowLeft,
    faArrowRight,
    faArrowTrendDown,
    faArrowTrendUp,
    faBan,
    faBarcode,
    faBars,
    faBell,
    faBuilding,
    faCalendar,
    faCalendarAlt,
    faCalendarDay,
    faCamera,
    faCar,
    faCarCrash,
    faCarSide,
    faCheck,
    faCheckCircle,
    faCheckSquare,
    faChevronLeft,
    faChevronRight,
    faCircle,
    faCircleCheck,
    faCircleExclamation,
    faCirclePause,
    faClipboardCheck,
    faClipboardList,
    faClock,
    faClone,
    faCoins,
    faComment,
    faCommentDollar,
    faDotCircle,
    faDownload,
    faEdit,
    faEnvelope,
    faEquals,
    faExchangeAlt,
    faExclamation,
    faExclamationTriangle,
    faExternalLink,
    faExternalLinkAlt,
    faExternalLinkSquareAlt,
    faEye,
    faEyeSlash,
    faFile,
    faFileAlt,
    faFileAudio,
    faFileCsv,
    faFileDownload,
    faFileExcel,
    faFileExport,
    faFileInvoice,
    faFileLines,
    faFilePdf,
    faFileUpload,
    faFileWord,
    faFilter,
    faFilterCircleXmark,
    faFlagCheckered,
    faGavel,
    faGear,
    faGlobe,
    faHandHoldingUsd,
    faHistory,
    faHome,
    faImages,
    faIndustry,
    faInfo,
    faInfoCircle,
    faKey,
    faLightbulb,
    faLink,
    faList,
    faLock,
    faLockOpen,
    faMapMarker,
    faMapMarkerAlt,
    faMinus,
    faMoneyBill,
    faMoneyBillAlt,
    faMoneyBillTransfer,
    faPaste,
    faPen,
    faPhone,
    faPlay,
    faPlus,
    faPoundSign,
    faPrint,
    faQrcode,
    faQuestion,
    faQuestionCircle,
    faRedo,
    faRedoAlt,
    faReply,
    faRightToBracket,
    faRoad,
    faRobot,
    faSave,
    faSearch,
    faServer,
    faSpinner,
    faStickyNote,
    faTasks,
    faTimes,
    faTimesCircle,
    faTrash,
    faTrashAlt,
    faTriangleExclamation,
    faTruck,
    faTruckFast,
    faTruckLoading,
    faTruckMoving,
    faUndo,
    faUndoAlt,
    faUnlink,
    faUpload,
    faUser,
    faUserCircle,
    faUserPlus,
    faUserSlash,
    faUserTag,
    faUserTimes,
    faWarehouse,
} from "@fortawesome/free-solid-svg-icons";

library.add(
    faArchive,
    faArrowAltCircleLeft,
    faArrowAltCircleRight,
    faArrowCircleLeft,
    faArrowCircleRight,
    faArrowLeft,
    faArrowRight,
    faArrowTrendDown,
    faArrowTrendUp,
    faBan,
    faBarcode,
    faBell,
    faBuilding,
    faCalendar,
    faCalendarAlt,
    faCalendarDay,
    faCamera,
    faCar,
    faCarCrash,
    faCarSide,
    faCheck,
    faCheckCircle,
    faCheckSquare,
    faChevronLeft,
    faChevronRight,
    faCircle,
    faCircleCheck,
    faCircleExclamation,
    faCirclePause,
    faClipboardCheck,
    faClipboardList,
    faClock,
    faClone,
    faCoins,
    faComment,
    faCommentDollar,
    faDotCircle,
    faDownload,
    faEdit,
    faEnvelope,
    faEquals,
    faExchangeAlt,
    faExclamation,
    faExclamationTriangle,
    faExternalLink,
    faExternalLinkAlt,
    faExternalLinkSquareAlt,
    faEye,
    faEyeSlash,
    faFile,
    faFileAlt,
    faFileAudio,
    faFileCsv,
    faFileDownload,
    faFileExcel,
    faFileExport,
    faFileInvoice,
    faFileLines,
    faFilePdf,
    faFileUpload,
    faFileWord,
    faFlagCheckered,
    faGavel,
    faGear,
    faGlobe,
    faHandHoldingUsd,
    faHistory,
    faHome,
    faImages,
    faIndustry,
    faInfo,
    faInfoCircle,
    faKey,
    faLightbulb,
    faLink,
    faList,
    faLock,
    faLockOpen,
    faMapMarker,
    faMapMarkerAlt,
    faMinus,
    faMoneyBill,
    faMoneyBillAlt,
    faMoneyBillTransfer,
    faPaste,
    faPen,
    faPhone,
    faPlay,
    faPlus,
    faPoundSign,
    faPrint,
    faQuestion,
    faQuestionCircle,
    faRedo,
    faRedoAlt,
    faReply,
    faRightToBracket,
    faRoad,
    faRobot,
    faSave,
    faSearch,
    faServer,
    faSpinner,
    faStickyNote,
    faTasks,
    faTimes,
    faTimesCircle,
    faTrash,
    faTrashAlt,
    faTriangleExclamation,
    faTruck,
    faTruckFast,
    faTruckLoading,
    faTruckMoving,
    faUndo,
    faUndoAlt,
    faUnlink,
    faUpload,
    faUser,
    faUserCircle,
    faUserPlus,
    faUserSlash,
    faUserTag,
    faUserTimes,
    faWarehouse,
    faQrcode,
    faFilterCircleXmark,
    faFilter,
    faBars
);
dom.watch();
