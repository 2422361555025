import { DvlaTransportStatsModel } from "@g3r-developers/vamp-types/dist/Transport/DVLA/DvlaTransportStatsModel";
import { PreArrangementReturnModel } from "@g3r-developers/vamp-types/dist/Transport/PreArrangementReturnModel";
import { TransportDocumentRequestModel } from "@g3r-developers/vamp-types/dist/Transport/TransportDocumentRequestModel";
import { TransportRequestDocsModel } from "@g3r-developers/vamp-types/dist/Transport/TransportRequestDocsModel";
import { TransportVendorDocumentRequestModel } from "@g3r-developers/vamp-types/dist/Transport/TransportVendorDocumentRequestModel";
import { VendorUploadTransportDocsModel } from "@g3r-developers/vamp-types/dist/Transport/VendorUploadTransportDocsModel";
import { DataTableResult } from "@g3r-developers/vamp-types/dist/data-table-result";
import { TransportDvlaFeeModel } from "@g3r-developers/vamp-types/dist/vehicle/Fee/TransportDvlaFeeModel";
import api from "../useApi";

const BaseUrl = "transport/dvla";

export interface UploadDvlaFeesModel {
    transport_dvla_fee_ids: number[];
}

const checkOutstandingDocs = (transportId: number) => {
    return api.post(`${BaseUrl}/check-outstanding-docs/${transportId}`);
};

const deleteDvlaFee = (transportDvlaFeeId: number) => {
    return api.patch(`${BaseUrl}/fees/fee/${transportDvlaFeeId}/delete`);
};

const getAwaitingArrangement = (model: any): Promise<DataTableResult<PreArrangementReturnModel>> => {
    return api.get(`${BaseUrl}/awaiting_arrangement`, model);
};

const getAwaitingDocuments = (model: any): Promise<DataTableResult<PreArrangementReturnModel>> => {
    return api.get(`${BaseUrl}/awaiting_docs`, model);
};

const getAwaitingPayment = (model: any): Promise<DataTableResult<PreArrangementReturnModel>> => {
    return api.get(`${BaseUrl}/awaiting_payment`, model);
};

const getAwaitingReceipt = (model: any): Promise<DataTableResult<PreArrangementReturnModel>> => {
    return api.get(`${BaseUrl}/awaiting_receipt`, model);
};

const getDocRequestsForTransport = async (transportId: number): Promise<TransportDocumentRequestModel[]> => {
    return api.get<TransportDocumentRequestModel[]>(`${BaseUrl}/doc-requests/${transportId}`);
};

const getDvlaFeesForTransport = (transportId: number): Promise<DataTableResult<TransportDvlaFeeModel>> => {
    return api.get(`${BaseUrl}/fees/${transportId}`);
};

const getDvlaFeeByFeeId = (transportDvlaFeeId: number) => {
    return api.get(`${BaseUrl}/fees/fee/${transportDvlaFeeId}`);
};

const getStats = async (): Promise<DvlaTransportStatsModel> => {
    return api.get<DvlaTransportStatsModel>(`${BaseUrl}/stats`);
};

const payDvlaFees = (model: UploadDvlaFeesModel, file?: File) => {
    return api.postFile(`${BaseUrl}/fees/paid`, file, model);
};

const updateDvlaFee = (transportDvlaFeeId: number, model: any) => {
    return api.put(`${BaseUrl}/fees/fee/${transportDvlaFeeId}`, model);
};

const uploadDvlaDocument = (transportId: number, documentTypeId: number, file: File) => {
    return api.postFile(`${BaseUrl}/upload-doc/${transportId}/${documentTypeId}`, file);
};

const uploadDvlaReceipt = (model: UploadDvlaFeesModel, file: File) => {
    return api.postFile(`${BaseUrl}/fees/upload-receipt`, file, model);
};

const validateDocRequestToken = (transportId: number, model: TransportRequestDocsModel): Promise<void> => {
    return api.get(`${BaseUrl}/validate-token/${transportId}`, model);
};

const vendorCheckOutstandingDocs = (transportId: number, model: TransportRequestDocsModel) => {
    return api.post(`${BaseUrl}/vendor/check-outstanding-docs/${transportId}`, model);
};

const vendorGetDocRequestsForTransport = async (
    transportId: number,
    model: TransportRequestDocsModel
): Promise<TransportVendorDocumentRequestModel> => {
    return api.get<TransportVendorDocumentRequestModel>(`${BaseUrl}/vendor/doc-requests/${transportId}`, model);
};

const vendorUploadDvlaDocument = (transportId: number, model: VendorUploadTransportDocsModel, file: File) => {
    return api.postFile(`${BaseUrl}/vendor/upload-doc/${transportId}`, file, model);
};

const transportDvlaService = {
    checkOutstandingDocs,
    deleteDvlaFee,
    getAwaitingArrangement,
    getAwaitingDocuments,
    getAwaitingPayment,
    getAwaitingReceipt,
    getDocRequestsForTransport,
    getDvlaFeeByFeeId,
    getDvlaFeesForTransport,
    getStats,
    payDvlaFees,
    updateDvlaFee,
    uploadDvlaDocument,
    uploadDvlaReceipt,
    validateDocRequestToken,
    vendorCheckOutstandingDocs,
    vendorGetDocRequestsForTransport,
    vendorUploadDvlaDocument,
};

export default transportDvlaService;
