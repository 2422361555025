import { G3Card, Loading } from "@g3r-developers/g3-common";
import { useTypedSearchParams } from "hooks/UseTypedSearchParams";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import ConsignmentService from "services/Consignment/ConsignmentService";
import ParameterUtils from "services/Utilities/ParameterUtils";

export default function ConsignmentAccept() {
    const [error, setError] = useState<string>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [success, setSuccess] = useState<boolean>(false);

    const { searchParams } = useTypedSearchParams();
    const token = useMemo(() => ParameterUtils.tryGetParam(searchParams, "key"), [searchParams]);

    const { consignmentId: consignmentIdParam } = useParams<{ consignmentId?: string }>();
    const consignmentId = useMemo(() => parseInt(consignmentIdParam), [consignmentIdParam]);

    const successMessage = "Your acceptance has been confirmed. Thank you.";
    const failMessage = "There was an issue with your request. Please contact G3.";

    const displayMessage = useMemo(() => (success ? successMessage : error ?? failMessage), [error, success]);

    useEffect(() => {
        if (!token || !consignmentId) {
            return;
        }

        ConsignmentService.acceptConsignmentWithToken(consignmentId, { token: token })
            .then(() => {
                setSuccess(true);
            })
            .catch(e => {
                const errorMessage = e.message ?? e;
                setError(typeof errorMessage === "string" ? errorMessage : undefined);
                setSuccess(false);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [consignmentId, token]);

    return (
        <G3Card>
            {loading ? (
                <Loading text={"Processing..."} />
            ) : (
                <div className="text-center">
                    <span>{displayMessage}</span>
                </div>
            )}
        </G3Card>
    );
}
