import ConsignmentAccept from "lib/Supplier/ConsignmentAccept";

export default function ConsignmentAcceptPage() {
    return (
        <div className="h-100">
            <div className={"m-5"}>
                <div className={"align-items-center flex-nowrap"}>
                    <div className="mb-3 w-50 text-center" style={{ margin: "0 auto" }}>
                        <img src="https://media.g3r.co.uk/logo.png" alt="G3 Vehicle Auctions" />
                    </div>
                    <ConsignmentAccept />
                </div>
            </div>
        </div>
    );
}
