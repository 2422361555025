import { useCallback, useMemo, useRef } from "react";
import { NavigateOptions, URLSearchParamsInit, createSearchParams, useLocation, useNavigate } from "react-router-dom";

// Mostly a copy of the react-router-dom implementation, but with typing, hash preservation, and a couple of utility functions
const useTypedSearchParams = <T>(defaultInit?: URLSearchParamsInit) => {
    type StringKey = Extract<keyof T, string>;

    const { hash, pathname, search } = useLocation();
    const navigate = useNavigate();

    const defaultSearchParamsRef = useRef<URLSearchParams>(createSearchParams(defaultInit));
    const hasSetSearchParamsRef = useRef<boolean>(false);

    const getSearchParamsForLocation = useCallback(
        (locationSearch: string, defaultSearchParams: URLSearchParams | null) => {
            const searchParams = createSearchParams(locationSearch);

            if (defaultSearchParams) {
                defaultSearchParams.forEach((_, key) => {
                    if (!searchParams.has(key)) {
                        defaultSearchParams.getAll(key).forEach(value => {
                            searchParams.append(key, value);
                        });
                    }
                });
            }

            return searchParams;
        },
        []
    );

    const searchParams = useMemo(
        () => getSearchParamsForLocation(search, hasSetSearchParamsRef.current ? null : defaultSearchParamsRef.current),
        [getSearchParamsForLocation, search]
    );

    const customSetSearchParams = useCallback(
        (
            nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
            navigateOpts?: NavigateOptions
        ) => {
            const newSearchParams = createSearchParams(
                typeof nextInit === "function" ? nextInit(searchParams) : nextInit
            );

            hasSetSearchParamsRef.current = true;

            navigate(
                {
                    pathname: pathname,
                    search: newSearchParams.toString(),
                    hash: hash,
                },
                navigateOpts
            );
        },
        [hash, navigate, pathname, searchParams]
    );

    const getParam = useCallback(
        (
            key: StringKey,
            type: "number" | "string",
            isArray?: boolean
        ): number | number[] | string | string[] | undefined => {
            const values = searchParams.getAll(key);

            if (!values.length) {
                return undefined;
            }

            if (type === "number") {
                const numberValues: number[] = [];

                for (const value of values) {
                    if (value.includes(",")) {
                        const split = value.split(",");
                        for (const v of split) {
                            const parsed = parseInt(v);

                            if (!isNaN(parsed)) {
                                numberValues.push(parsed);
                            }
                        }
                    } else {
                        const parsed = parseInt(value);

                        if (!isNaN(parsed)) {
                            numberValues.push(parsed);
                        }
                    }
                }

                return isArray ? numberValues : numberValues[0];
            }

            return isArray ? values : values[0];
        },
        [searchParams]
    );

    const setParam = useCallback(
        (key: StringKey, value: number | number[] | boolean | string | string[]) =>
            customSetSearchParams(
                p => {
                    if (value === null || value === undefined) {
                        p.delete(key);
                    } else if (Array.isArray(value)) {
                        p.delete(key);

                        for (const v of value) {
                            p.append(key, `${v}`);
                        }
                    } else {
                        p.set(key, `${value}`);
                    }

                    return p;
                },
                { replace: true }
            ),
        [customSetSearchParams]
    );

    return {
        getParam,
        searchParams,
        setParam,
        setSearchParams: customSetSearchParams,
    };
};

export { useTypedSearchParams };
