import styles from "./LoginLayoutPage.module.css";

export default function LoginLayoutPage({ component: Component }) {
    return (
        <div className="h-100">
            <div className={styles.loginBg}></div>
            <div className={styles.loginFormContainer + " d-flex flex-wrap"}>
                <div className={styles.loginForm + " align-self-center w-100"}>
                    <div className="mb-3 w-50 text-center" style={{ margin: "0 auto" }}>
                        <img src="/images/vamp_v1.png" alt="G3 Vehicle Auctions" className="w-25" />
                    </div>
                    {Component}
                </div>
            </div>
        </div>
    );
}
