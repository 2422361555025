import { Loading } from "@g3r-developers/g3-common";
import { useTypedSearchParams } from "hooks/UseTypedSearchParams";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ParameterUtils from "services/Utilities/ParameterUtils";
import VehicleRefurbService from "services/vehicle/VehicleRefurbService";
import RefurbReviewCard from "./RefurbReviewCard";

export default function VendorTransportDocuments() {
    const navigate = useNavigate();

    const [authenticating, setAuthenticating] = useState<boolean>(true);
    const [tokenAuthenticated, setTokenAuthenticated] = useState<boolean>(false);
    const [completed, setCompleted] = useState<boolean>(false);

    const { searchParams } = useTypedSearchParams();
    const token = useMemo(() => ParameterUtils.tryGetParam(searchParams, "key"), [searchParams]);

    const { refurbId: refurbIdParam } = useParams<{ refurbId?: string }>();
    const refurbId = useMemo(() => parseInt(refurbIdParam), [refurbIdParam]);

    const failMessage = "There was an issue with your request. Please contact G3.";

    const authenticateToken = useCallback(() => {
        if (!token || !refurbId) {
            navigate("/login");
            return;
        }

        VehicleRefurbService.validateRefurbReviewToken(refurbId, { token: token })
            .then(() => {
                setTokenAuthenticated(true);
            })
            .catch(() => {
                setTokenAuthenticated(false);
            })
            .finally(() => {
                setAuthenticating(false);
            });
    }, [refurbId, token, navigate]);

    const handleCompleted = useCallback(() => {
        setCompleted(true);
    }, []);

    useEffect(() => {
        authenticateToken();
    }, [authenticateToken]);

    return (
        <>
            {authenticating ? (
                <Loading text={"Authenticating..."} />
            ) : (
                <>
                    {tokenAuthenticated ? (
                        <>
                            {completed ? (
                                <div className="text-center">
                                    <span>This refurbishment has been reviewed</span>
                                </div>
                            ) : (
                                <RefurbReviewCard token={token} refurbId={refurbId} onCompleted={handleCompleted} />
                            )}
                        </>
                    ) : (
                        <div className="text-center">
                            <span>{failMessage}</span>
                        </div>
                    )}
                </>
            )}
        </>
    );
}
