export enum CONTACT_GROUPS {
    STAFF_BUYER_APPLICATIONS = 1,
    STAFF_MANAGEMENT = 2,
    STAFF_TRANSPORT = 3,
    STAFF_ACCOUNT_MANAGER = 4,
    STAFF_YARD = 5,
    STAFF_ACCOUNTS = 6,
    VENDOR_GENERAL = 7,
    STAFF_ROSTRUM = 8,
    SUPPLIER_GENERAL = 9,
    STAFF_SALES_REP = 10,
    BUYER_GENERAL = 11,
    TRANSPORT_MANAGEMENT = 12,
}

export enum ContactGroupNames {
    STAFF_BUYER_APPLICATIONS = "Buyer Applications",
    STAFF_MANAGEMENT = "Management",
    STAFF_TRANSPORT = "Transport",
    STAFF_ACCOUNT_MANAGER = "Account Manager",
    STAFF_YARD = "Yard",
    STAFF_YARD_MANAGEMENT = "Yard Management",
    STAFF_ACCOUNTS = "Accounts",
    STAFF_ROSTRUM = "Rostrum",
    STAFF_BUYER_SERVICES = "Buyer Services",
    STAFF_COMPLIANCE = "Compliance",
    STAFF_FRONT_DESK = "Front Desk",
    TRANSPORT_MANAGEMENT = "Transport Management",
}
