import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function useQueryParams() {
    const [params, setParams] = useState<URLSearchParams>(undefined);

    const location = useLocation();

    useEffect(() => {
        setParams(new URLSearchParams(location.search));
    }, [location]);

    return params;
}
