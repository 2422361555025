import { G3Card, LabelValCol, Loading } from "@g3r-developers/g3-common";
import { TransportDocumentRequestModel } from "@g3r-developers/vamp-types/dist/Transport/TransportDocumentRequestModel";
import { TransportModel } from "@g3r-developers/vamp-types/dist/Transport/TransportModel";
import { TransportVendorDocumentRequestModel } from "@g3r-developers/vamp-types/dist/Transport/TransportVendorDocumentRequestModel";
import useVampToasts from "hooks/UseVampToasts";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import transportDvlaService from "services/Transport/TransportDvlaService";
import { useImmer } from "use-immer";
import VendorUploadDocumentCard from "./VendorUploadDocumentCard";

const UploadedStatusName = "uploaded";

interface VendorDvlaUploadDocsProps {
    onCompleted?: () => void;
    token: string;
    transportId: number;
}

export default function VendorDvlaUploadDocs({ onCompleted, token, transportId }: VendorDvlaUploadDocsProps) {
    const [loading, setLoading] = useState<boolean>(true);
    const [requests, setRequests] = useImmer<TransportDocumentRequestModel[]>([]);
    const [transport, setTransport] = useState<TransportModel>(undefined);

    const { errorToast, successToast } = useVampToasts();

    const isCompleted = useCallback((r: TransportDocumentRequestModel[]) => {
        let completed = true;

        r.forEach(request => {
            if (request.request_status.name !== UploadedStatusName) {
                completed = false;
            }
        });

        return completed;
    }, []);

    const handleCompleted = useCallback(async () => {
        transportDvlaService.vendorCheckOutstandingDocs(transportId, { token: token });
        successToast("All documents have been uploaded");

        if (onCompleted) {
            onCompleted();
        }
    }, [onCompleted, successToast, token, transportId]);

    const handleRequestsLoaded = useCallback(
        (r: TransportVendorDocumentRequestModel) => {
            if (isCompleted(r.document_requests)) {
                handleCompleted();
            } else {
                setTransport(r.transport);
                setRequests(draft => r.document_requests);
            }

            setLoading(false);
        },
        [isCompleted, setRequests, handleCompleted]
    );

    const loadRequests = useCallback(() => {
        transportDvlaService
            .vendorGetDocRequestsForTransport(transportId, { token: token })
            .then(r => handleRequestsLoaded(r))
            .catch(errorToast);
    }, [errorToast, handleRequestsLoaded, token, transportId]);

    useEffect(() => {
        loadRequests();
    }, [loadRequests]);

    return (
        <>
            {transport && (
                <G3Card title="Vehicle Details">
                    <Row>
                        <LabelValCol title="VRM" value={transport.vehicle.vrm} />
                        <LabelValCol
                            title="Make/Model"
                            value={`${transport.vehicle.make} ${transport.vehicle.model}`}
                        />
                    </Row>
                </G3Card>
            )}

            {loading ? (
                <Loading />
            ) : (
                <>
                    <Row>
                        <Col>
                            <h2 className="page-title">Please upload the following documents:</h2>
                        </Col>
                    </Row>

                    <Row>
                        {requests.map(request => (
                            <Col md="4">
                                <VendorUploadDocumentCard
                                    loadRequests={loadRequests}
                                    request={request}
                                    token={token}
                                    transportId={transportId}
                                />
                            </Col>
                        ))}
                    </Row>
                </>
            )}
        </>
    );
}
