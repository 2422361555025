import { useMemo } from "react";
import { MediaModel } from "types/media/media-model";

interface ProfilePicProps {
    media?: MediaModel;
    url?: string;
    width: number;
    className?: string;
}

export default function ProfilePic({ media, url, width, className }: ProfilePicProps) {
    const defaultImage =
        "https://demos.wrappixel.com/premium-admin-templates/bootstrap/adminpro-bootstrap/package/assets/images/users/1.jpg";

    const onError = e => {
        e.target.src = defaultImage;
    };

    const img = useMemo(() => {
        if (media) {
            return media.url;
        }
        if (url) {
            return url;
        }

        return defaultImage;
    }, [media, url]);

    const constructedClass = useMemo(() => {
        return `rounded-circle ${className}`;
    }, [className]);

    return (
        <div className="profile-pic mb-3 mt-3">
            <img onError={onError} src={img} className={constructedClass} style={{ width: `${width}px` }} alt={""} />
        </div>
    );
}
