import { ListGroup } from "reactstrap";
import { NoteDisplay } from "./NoteDisplay";
import { NoteModel } from "types/note/note-model";

interface NoteListProps {
    notes: NoteModel[];
}

export default function NoteList({ notes }: NoteListProps) {
    return (
        <ListGroup className={"mt-3 note-list"}>
            {notes.map(n => (
                <NoteDisplay key={n.note_id} note={n} />
            ))}
        </ListGroup>
    );
}
