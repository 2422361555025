import { DateTime } from "luxon";

export const clearCache = (reloadAfterClear = true) => {
    if ("caches" in window) {
        caches
            .keys()
            .then(async keys => {
                keys.forEach(async name => {
                    await caches.delete(name);
                });
            })
            .then(() => {
                if (reloadAfterClear) {
                    window.location.reload();
                }
            });
    }
};

export const formatMoney = (value: number, integer?: boolean): string => {
    const formatter = new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
    });

    const formatted = formatter.format(value);

    return integer ? formatted.split(".")[0] : formatted;
};

export const g3Currency = (value: number | string): string => {
    const valueString = value.toString();

    if (value[0] !== "-") {
        return `£${valueString}`;
    }

    const charArray = valueString.split("");
    charArray.splice(1, 0, `£`);

    return charArray.join("");
};

export const isDefaultDate = (isoString: string): boolean => {
    return DateTime.fromISO(isoString).toISO() === defaultISODate.toISO();
};

export const isEmpty = (obj: object): boolean => {
    return !obj || !Object.keys(obj).length;
};

export const isNullOrUndefined = (value: any): boolean => {
    return value === null || value === undefined;
};

export const isNullOrEmpty = (value: string): boolean => {
    return isNullOrUndefined(value) || value.trim() === "";
};

export const powerset = <T>(arr: T[]): T[][] => arr.reduce((a, v) => a.concat(a.map(r => r.concat(v))), [[]] as T[][]);

export const sum = (values: number[]): number => {
    return values.reduce((a, b) => a + b, 0);
};

export const titleCase = (str: string): string => {
    const splitStr = str.toLowerCase().split(" ");

    for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].slice(1);
    }

    return splitStr.join(" ");
};

export const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
        (groups[key(item)] ||= []).push(item);
        return groups;
    }, {} as Record<K, T[]>);

export const dateFormat = "dd/MM/yyyy";
export const timeFormat = `HH:mm`;
export const dateTimeFormat = `${dateFormat} ${timeFormat}`;

export const defaultISODate = DateTime.fromISO("0001-01-01T00:00:00");

export const toDateString = (value: string): string => DateTime.fromISO(value).toFormat(dateFormat);
export const toDateTimeString = (value: string): string => DateTime.fromISO(value).toFormat(dateTimeFormat);
export const toTimeString = (value: string): string => DateTime.fromISO(value).toFormat(timeFormat);

const hex = (c: number): string => {
    if (c === 0 || isNaN(c)) {
        return "00";
    }

    const s = "0123456789abcdef";
    const i = Math.round(Math.min(Math.max(0, c), 255));

    return s.charAt((i - (i % 16)) / 16) + s.charAt(i % 16);
};

const convertToHex = (rgb: number[]): string => `#${hex(rgb[0])}${hex(rgb[1])}${hex(rgb[2])}`;

const trim = (s: string): string => (s.charAt(0) === "#" ? s.substring(1, 7) : s);

const convertToRgb = (hex: string): number[] => {
    const trimmedHex = trim(hex);
    const color = [
        parseInt(trimmedHex.substring(0, 2), 16),
        parseInt(trimmedHex.substring(2, 4), 16),
        parseInt(trimmedHex.substring(4, 6), 16),
    ];
    return color;
};

export const generateColors = (colorStart: string, colorEnd: string, colorCount: number): string[] => {
    const start = convertToRgb(colorStart);
    const end = convertToRgb(colorEnd);
    let alpha = 0.0;

    const colors: string[] = [colorStart];

    for (let i = 1; i < colorCount - 1; i++) {
        alpha += 1.0 / colorCount;

        const c = [
            end[0] * alpha + (1 - alpha) * start[0],
            end[1] * alpha + (1 - alpha) * start[1],
            end[2] * alpha + (1 - alpha) * start[2],
        ];

        colors.push(convertToHex(c));
    }

    colors.push(colorEnd);

    return colors;
};

export const updateTitle = (newVal: string) => {
    document.title = `VAMP - ${newVal}`;
};

export const fileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

export const b64toBlob = (b64Data: string) => {
    // Split the base64 string in data and contentType
    const block = b64Data.split(";");

    // Get the content type of the image
    let contentType = block[0].split(":")[1];

    // get the real base64 content of the file
    b64Data = block[1].split(",")[1];

    contentType = contentType || "";
    const sliceSize = 512;

    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
};

export const undefinedIfNaN = (value: number) => (isNaN(value) ? undefined : value);
