import { emailIsValid, G3Input, IconButton, Loading } from "@g3r-developers/g3-common";
import { PasswordResetModel } from "@g3r-developers/vamp-types/dist/User/PasswordResetModel";
import { useState } from "react";
import { FormGroup } from "reactstrap";
import userPasswordService from "services/user/UserPasswordService";
import { isEmpty } from "services/utils";

interface ForgotPasswordFormErrors {
    submit: string;
    email: string;
}

export default function ForgotPassword() {
    const [data, setData] = useState<PasswordResetModel>({
        accountType: 2, // 2 is VAMP
    } as PasswordResetModel);
    const [error, setError] = useState<ForgotPasswordFormErrors>({} as ForgotPasswordFormErrors);
    const [forgotPasswordSent, setForgotPasswordSent] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);

    const checkErrors = () => {
        const errors = {} as ForgotPasswordFormErrors;

        if (!data.email || data.email.trim() === "" || !emailIsValid(data.email)) {
            errors.email = "Please enter a valid email address";
        }

        setError(errors);
        return errors;
    };

    function handleSubmit() {
        const errors = checkErrors();

        if (!isEmpty(errors)) {
            return;
        }

        setLoader(true);
        userPasswordService
            .resetPassword(data)
            .then(() => setForgotPasswordSent(true))
            .catch(e => setError({ submit: e } as ForgotPasswordFormErrors))
            .finally(() => {
                setLoader(false);
            });
    }

    return (
        <>
            {forgotPasswordSent ? (
                <>
                    <div className="mb-3 w-50" style={{ margin: "0 auto" }}>
                        <p>An email has been sent to your email address with password reset instructions.</p>
                        <p>
                            Please check your email and re-submit the request if you do not receive it within 5 minutes.
                        </p>
                    </div>
                    <div className="mb-3 w-50" style={{ margin: "0 auto" }}>
                        <IconButton url="/" block variant="primary" text="Back" />
                    </div>
                </>
            ) : (
                <>
                    <FormGroup className="mb-3 w-50" style={{ margin: "0 auto" }}>
                        <div className="mb-3">
                            Please enter your email address and submit to receive an email to reset your password
                        </div>
                        <G3Input
                            label="Email"
                            type="email"
                            error={error.email}
                            onChange={(e: string) =>
                                setData(r => {
                                    return { ...r, email: e };
                                })
                            }
                            value={data.email}
                        />
                    </FormGroup>

                    {!!error.submit && <div className="mb-3 text-danger text-center text-bold">{error.submit}</div>}

                    <div className="mb-3 w-50" style={{ margin: "0 auto" }}>
                        {loader ? (
                            <Loading />
                        ) : (
                            <IconButton block variant="primary" onClick={handleSubmit} text="Submit" />
                        )}
                    </div>
                </>
            )}
        </>
    );
}
