import { G3Input, IconButton, PasswordInput, showokmodal } from "@g3r-developers/g3-common";
import { UserVerifyModel } from "@g3r-developers/vamp-types/dist/User/UserVerifyModel";
import useVampToasts from "hooks/UseVampToasts";
import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Col, FormGroup, Row } from "reactstrap";
import UserService from "services/user/UserService";

interface UserVerifyErrors {
    password?: string;
    confirmPassword?: string;
}

export default function VerifyAccount() {
    const navigate = useNavigate();

    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const { errorToast } = useVampToasts();

    const { userId: userIdParam } = useParams<{ userId?: string }>();

    const userId = useMemo(() => {
        if (!userIdParam) {
            return -1;
        }

        return Number(userIdParam);
    }, [userIdParam]);

    if (!userId || !params.get("token")) {
        navigate("/", { replace: true });
    }

    const [data, setData] = useState<UserVerifyModel>({
        user_id: userId,
        token: params.get("token"),
        password: "",
    });
    const [errors, setErrors] = useState<UserVerifyErrors>({});
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);

    const checkErrors = useCallback((model: UserVerifyModel, isPasswordValid: boolean) => {
        const e: UserVerifyErrors = {};

        if (!isPasswordValid || !model.password?.trim()) {
            e.password = "Please set a valid password";
        }

        if (model.password !== model.confirmPassword) {
            e.confirmPassword = "Both passwords must match";
        }

        setErrors(e);
        return e;
    }, []);

    const userVerified = useCallback(() => {
        showokmodal("Your account has been verified, you can now log in").then(() => navigate("/"));
    }, [navigate]);

    const submit = useCallback(() => {
        const e = checkErrors(data, isPasswordValid);

        if (Object.keys(e).length !== 0) {
            return;
        }

        UserService.verify(data).then(userVerified).catch(errorToast);
    }, [data, checkErrors, errorToast, isPasswordValid, userVerified]);

    return (
        <Row className={"p-4"}>
            <Col>
                <h4 className="g3-header">Verify Account</h4>

                <FormGroup>
                    <PasswordInput
                        error={errors.password}
                        label={"Set Password"}
                        onChange={val => setData(p => ({ ...p, password: val }))}
                        onValidation={setIsPasswordValid}
                        value={data.password}
                    />
                </FormGroup>

                <FormGroup>
                    <G3Input
                        error={errors.confirmPassword}
                        label="Confirm Password"
                        type="password"
                        value={data.confirmPassword}
                        onChange={(e: string) => setData(p => ({ ...p, confirmPassword: e }))}
                    />
                </FormGroup>

                <IconButton variant="primary" block text="Verify Account" onClick={submit} />
            </Col>
        </Row>
    );
}
