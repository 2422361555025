import { useMemo } from "react";
import { Link } from "react-router-dom";

export const NoteWord = ({ word }: { word: string }) => {
    const isObject = useMemo(() => {
        return word.trim().startsWith("{") && word.trim().endsWith("}");
    }, [word]);

    const display = useMemo(() => {
        if (!isObject) {
            return word;
        }

        const w = JSON.parse(word);

        return (
            <>
                <Link to="#" className="text-red">
                    @{w.value}
                </Link>
            </>
        );
    }, [isObject, word]);

    return <>{display}</>;
};
