import { G3Card, Loading } from "@g3r-developers/g3-common";
import { useTypedSearchParams } from "hooks/UseTypedSearchParams";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import transportDvlaService from "services/Transport/TransportDvlaService";
import ParameterUtils from "services/Utilities/ParameterUtils";
import VendorDvlaUploadDocs from "./VendorDvlaUploadDocs";

export default function VendorTransportDocuments() {
    const [authenticating, setAuthenticating] = useState<boolean>(true);
    const [uploaded, setUploaded] = useState<boolean>(false);
    const [tokenAuthenticated, setTokenAuthenticated] = useState<boolean>(false);

    const { searchParams } = useTypedSearchParams();
    const token = useMemo(() => ParameterUtils.tryGetParam(searchParams, "key"), [searchParams]);

    const { transportId: transportIdParam } = useParams<{ transportId?: string }>();
    const transportId = useMemo(() => parseInt(transportIdParam), [transportIdParam]);

    const failMessage = "There was an issue with your request. Please contact G3.";
    const uploadedMessage = "All requested documents have been uploaded. Thank you.";

    const authenticateToken = useCallback(() => {
        if (!token || !transportId) {
            return;
        }

        transportDvlaService
            .validateDocRequestToken(transportId, { token: token })
            .then(() => {
                setTokenAuthenticated(true);
            })
            .catch(() => {
                setTokenAuthenticated(false);
            })
            .finally(() => {
                setAuthenticating(false);
            });
    }, [transportId, token]);

    const handleCompleted = useCallback(() => {
        setUploaded(true);
    }, []);

    useEffect(() => {
        authenticateToken();
    }, [authenticateToken]);

    return (
        <G3Card>
            {authenticating ? (
                <Loading text={"Authenticating..."} />
            ) : (
                <>
                    {tokenAuthenticated ? (
                        <>
                            {uploaded ? (
                                <div className="text-center">
                                    <span>{uploadedMessage}</span>
                                </div>
                            ) : (
                                <VendorDvlaUploadDocs
                                    onCompleted={handleCompleted}
                                    token={token}
                                    transportId={transportId}
                                />
                            )}
                        </>
                    ) : (
                        <div className="text-center">
                            <span>{failMessage}</span>
                        </div>
                    )}
                </>
            )}
        </G3Card>
    );
}
