export const DisplayAlertBar = (registration: ServiceWorkerRegistration) => {
    const update = (bar: HTMLDivElement, registration: ServiceWorkerRegistration) => {
        bar.remove();

        const waitingWorker = registration.waiting;

        if (waitingWorker) {
            waitingWorker.postMessage({ type: "SKIP_WAITING" });
        }

        window.location.reload();
    };

    const bar = document.createElement("div");
    bar.innerHTML = "A new version of VAMP is available now - click here to get it!";
    bar.classList.add("alert-bar");
    bar.onclick = () => update(bar, registration);
    document.getElementById("alertheader").appendChild(bar);
};

export default DisplayAlertBar;
