import { PasswordResetModel } from "@g3r-developers/vamp-types/dist/User/PasswordResetModel";
import { SetPasswordModel } from "@g3r-developers/vamp-types/dist/User/SetPasswordModel";
import api from "services/useApi";

const baseUrl = "user/password";

export const resetPassword = (data: PasswordResetModel) => {
    return api.post(`${baseUrl}/reset`, data);
};

export const setPassword = (data: SetPasswordModel) => {
    return api.post(`${baseUrl}/set`, data);
};

const userPasswordService = {
    resetPassword,
    setPassword,
};

export default userPasswordService;
