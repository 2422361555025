import {
    ApproveVehicleRefurbModel,
    CompleteVehicleRefurbModel,
    CreateRefurbModel,
    QuoteVehicleRefurbModel,
    RefurbArrangementModel,
    RefurbQuoteModel,
    RefurbStatsModel,
    RefurbVendorChargeModel,
    ReviewRefurbUpdateModel,
    UpdateRefurbModel,
    VehicleRefurbModel,
    VehicleRefurbSearchModel,
    VehicleRefurbTabReturnModel,
    VehicleRefurbTableReturnModel,
} from "@g3r-developers/vamp-types/dist";
import { TransportRequestDocsModel } from "@g3r-developers/vamp-types/dist/Transport/TransportRequestDocsModel";
import { DataTableResult } from "@g3r-developers/vamp-types/dist/data-table-result";
import { MediaModel } from "@g3r-developers/vamp-types/dist/media/media-model";
import { PagingModel } from "@g3r-developers/vamp-types/dist/paging-model";
import { RefurbSupplierChargeModel } from "@g3r-developers/vamp-types/dist/vehicle/Refurb/RefurbSupplierChargeModel";
import { RequestSupplierRefurbQuoteModel } from "@g3r-developers/vamp-types/dist/vehicle/Refurb/RequestSupplierRefurbQuoteModel";
import { SuggestedRefurbModel } from "types/vehicle/gating/SuggestedRefurbModel";
import { ReviewRefurbModel } from "types/vehicle/refurb/ReviewRefurbModel";
import { VehicleRefurbTaskSearchModel } from "types/vehicle/refurb/VehicleRefurbTaskSearchModel";
import { VehicleRefurbVehicleTaskReturn } from "types/vehicle/refurb/VehicleRefurbVehicleTaskReturn";
import api from "../useApi";

const BaseUrl = "vehicle/refurb";

const addQuoteToRefurb = (refurbId: number, data: QuoteVehicleRefurbModel): Promise<VehicleRefurbModel> => {
    return api.post(`${BaseUrl}/quote/${refurbId}`, data);
};

const create = async (vehicleId: number, model: CreateRefurbModel): Promise<VehicleRefurbModel> => {
    return api.post<VehicleRefurbModel>(`vehicle/${vehicleId}/refurb`, model);
};

const createSuggested = (vehicleId: number, files: File[], model: SuggestedRefurbModel) => {
    return api.postFiles(`vehicle/${vehicleId}/suggested`, files, model);
};

const dismiss = (vehicleRefurbId: number): Promise<void> => {
    return api.patch(`${BaseUrl}/${vehicleRefurbId}/dismiss`, undefined);
};

const get = (refurbId: number): Promise<VehicleRefurbModel> => {
    return api.get(`${BaseUrl}/${refurbId}`);
};

const getRefurbMedia = (refurbId: number): Promise<MediaModel[]> => {
    return api.get<MediaModel[]>(`${BaseUrl}/${refurbId}/media`);
};

const getStats = (): Promise<RefurbStatsModel> => {
    return api.get(`${BaseUrl}/stats`);
};

const getByTaskId = (
    taskId: number,
    model: VehicleRefurbTaskSearchModel
): Promise<DataTableResult<VehicleRefurbVehicleTaskReturn>> => {
    return api.get(`${BaseUrl}/task/${taskId}`, model);
};

const acceptRefurbQuote = (vehicleRefurbId: number, model: ApproveVehicleRefurbModel): Promise<VehicleRefurbModel> => {
    return api.post(`${BaseUrl}/${vehicleRefurbId}/approve`, model);
};

const vendorAcceptRefurbQuote = (refurbId: number, model: TransportRequestDocsModel): Promise<VehicleRefurbModel> => {
    return api.post(`${BaseUrl}/${refurbId}/vendor/approve`, model);
};

const rejectRefurbQuote = (vehicleRefurbId: number): Promise<VehicleRefurbModel> => {
    return api.post(`${BaseUrl}/${vehicleRefurbId}/reject`);
};

const vendorRejectRefurbQuote = (refurbId: number, model: TransportRequestDocsModel): Promise<VehicleRefurbModel> => {
    return api.post(`${BaseUrl}/${refurbId}/vendor/reject`, model);
};

const vendorUpdateRefurbQuote = (refurbId: number, model: ReviewRefurbUpdateModel): Promise<VehicleRefurbModel> => {
    return api.post(`${BaseUrl}/${refurbId}/vendor/update`, model);
};

const thirdPartyUpdateRefurbQuote = (refurbId: number, model: ReviewRefurbUpdateModel): Promise<VehicleRefurbModel> => {
    return api.post(`${BaseUrl}/${refurbId}/third-party/update`, model);
};

const calculateAmountChargedToVendor = (model: RefurbVendorChargeModel): Promise<number> => {
    return api.post(`${BaseUrl}/calc-vendor-charge`, model);
};

const calculateAmountSupplierCharge = (model: RefurbSupplierChargeModel): Promise<number> => {
    return api.post(`${BaseUrl}/calc-supplier-charge`, model);
};

const arrangeRefurb = (vehicleRefurbId: number, model: RefurbArrangementModel): Promise<VehicleRefurbModel> => {
    return api.post(`${BaseUrl}/${vehicleRefurbId}/arrange`, model);
};

/**
 * @deprecated Use vehicleRefurbService.complete instead, which takes a CompleteVehicleRefurbModel.
 */
const completeRefurb = (vehicleRefurbId: number, taskId: number): Promise<VehicleRefurbModel> => {
    return api.post(`${BaseUrl}/${vehicleRefurbId}/complete/${taskId}`);
};

// Using TransportRequestDocsModel as it's just token string
const vendorReviewForRefurb = async (
    refurbId: number,
    model: TransportRequestDocsModel
): Promise<ReviewRefurbModel> => {
    return api.get<ReviewRefurbModel>(`${BaseUrl}/${refurbId}/review`, model);
};

// Using TransportRequestDocsModel as it's just token string
const validateRefurbReviewToken = (refurbId: number, model: TransportRequestDocsModel): Promise<void> => {
    return api.get(`${BaseUrl}/validate-token/${refurbId}`, model);
};

const getRefurbsForVehicle = async (
    vehicleId: number,
    model: PagingModel
): Promise<DataTableResult<VehicleRefurbTabReturnModel>> =>
    api.get<DataTableResult<VehicleRefurbTabReturnModel>>(`vehicle/${vehicleId}/refurb`, model);

const getRefurbs = async (model: VehicleRefurbSearchModel): Promise<DataTableResult<VehicleRefurbTableReturnModel>> =>
    api.get(`${BaseUrl}`, model);

const updateRefurb = (refurbId: number, model: UpdateRefurbModel) => {
    return api.patch(`${BaseUrl}/${refurbId}/update`, model);
};

const cancelRefurb = (refurbId: number) => {
    return api.patch(`${BaseUrl}/${refurbId}/cancel`);
};

const complete = async (vehicleRefurbId: number, model: CompleteVehicleRefurbModel): Promise<VehicleRefurbModel> =>
    api.post<VehicleRefurbModel>(`${BaseUrl}/${vehicleRefurbId}/complete`, model);

const requestQuoteFromSupplier = (
    refurbId: number,
    data: RequestSupplierRefurbQuoteModel
): Promise<VehicleRefurbModel> => {
    return api.post(`${BaseUrl}/quote/${refurbId}/request-supplier`, data);
};

const cancelQuoteRequestFromSupplier = (quoteId: number): Promise<VehicleRefurbModel> => {
    return api.post(`${BaseUrl}/quote/${quoteId}/cancel-request-supplier`);
};

const rejectSupplierQuote = (quoteId: number): Promise<VehicleRefurbModel> => {
    return api.post(`${BaseUrl}/quote/${quoteId}/supplier-reject`);
};

const acceptSupplierQuoteForRefurb = (
    refurbId: number,
    quoteId: number,
    data: QuoteVehicleRefurbModel
): Promise<VehicleRefurbModel> => {
    return api.post(`${BaseUrl}/${refurbId}/quote/${quoteId}/supplier-accept`, data);
};

const getSupplierQuotesForRefurb = (refurbId: number): Promise<RefurbQuoteModel[]> => {
    return api.get(`${BaseUrl}/${refurbId}/supplier-quotes`);
};

const VehicleRefurbService = {
    acceptRefurbQuote,
    acceptSupplierQuoteForRefurb,
    addQuoteToRefurb,
    arrangeRefurb,
    calculateAmountChargedToVendor,
    calculateAmountSupplierCharge,
    cancelQuoteRequestFromSupplier,
    cancelRefurb,
    complete,
    completeRefurb,
    create,
    createSuggested,
    dismiss,
    get,
    getByTaskId,
    getRefurbMedia,
    getRefurbs,
    getRefurbsForVehicle,
    getStats,
    getSupplierQuotesForRefurb,
    rejectRefurbQuote,
    rejectSupplierQuote,
    requestQuoteFromSupplier,
    thirdPartyUpdateRefurbQuote,
    updateRefurb,
    validateRefurbReviewToken,
    vendorAcceptRefurbQuote,
    vendorRejectRefurbQuote,
    vendorReviewForRefurb,
    vendorUpdateRefurbQuote,
};

export default VehicleRefurbService;
