import { VampLoginResponse } from "@g3r-developers/vamp-types/dist";
import { RootState } from "index";
import { useSelector } from "react-redux";

const useSession = (): VampLoginResponse => {
    const session = useSelector((state: RootState) => state.session.user);
    return session;
};

export { useSession };
