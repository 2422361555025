import ProfilePic from "lib/common/profile-pic";
import { useMemo } from "react";
import { ListGroupItem } from "reactstrap";
import { NoteWord } from "./NoteWord";
import { NoteModel } from "types/note/note-model";

export const NoteDisplay = ({ note }: { note: NoteModel }) => {
    const noteText = useMemo(() => {
        const split = note.note_text.split("[[");
        const final = [];
        split.forEach(s => {
            const split2 = s.split("]]");
            split2.forEach(s2 => {
                final.push(s2);
            });
        });

        return (
            <>
                {final.map(str => (
                    <NoteWord key={str} word={str} />
                ))}
            </>
        );
    }, [note.note_text]);

    return (
        <ListGroupItem className="activity-item d-flex" key={`note-${note.note_id}`}>
            <div className="me-3">
                <ProfilePic media={note.created_contact?.profile_picture} width={50} />
            </div>
            <div className="mt-2 ms-2">
                <div className="mb-0 text-muted font-sm">
                    {note.created_contact?.full_name ?? "Inactive User"} @ {note.added_string}
                </div>
                <h6 style={{ whiteSpace: "pre-line" }}>{noteText}</h6>
            </div>
        </ListGroupItem>
    );
};
