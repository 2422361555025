import { VampLoginResponse } from "@g3r-developers/vamp-types/dist";
import { useValidateSession } from "hooks/UseValidateSession";
import useVampToasts from "hooks/UseVampToasts";
import { RootState } from "index";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

interface AuthorisedRouteProps {
    roles: string[];
}

function AuthorisedRoute({ roles }: AuthorisedRouteProps) {
    const [checked, setChecked] = useState<boolean>(false);
    const [authorised, setAuthorised] = useState<boolean>(false);

    const user = useSelector((state: RootState) => state.session.user);

    const authenticated = useValidateSession();

    const checkUserRoles = useCallback((roles: string[], user: VampLoginResponse) => {
        let authorised = false;
        const userContactTypes = user.contact_types.map(_ => _.toLowerCase());
        const userContactGroups = user.contact_groups.map(_ => _.toLowerCase());

        roles.forEach(_ => {
            const role = _.toLowerCase();
            if (role.includes(":")) {
                const splitRole = role.split(":", 2);
                if (userContactTypes.includes(splitRole[0]) && userContactGroups.includes(splitRole[1])) {
                    authorised = true;
                }
            } else {
                if (userContactTypes.includes(role)) {
                    authorised = true;
                }

                if (userContactGroups.includes(role)) {
                    authorised = true;
                }
            }
        });

        setAuthorised(authorised);
        setChecked(true);
    }, []);

    useEffect(() => {
        if (!roles) {
            setChecked(true);
            return;
        }

        checkUserRoles(roles, user);
    }, [checkUserRoles, roles, user]);

    const isAuthorised = useMemo(() => authenticated && authorised, [authenticated, authorised]);

    return checked && <>{isAuthorised ? <Outlet /> : <UnauthorisedRedirect />}</>;
}

export default AuthorisedRoute;

function UnauthorisedRedirect() {
    const { errorToast } = useVampToasts();

    useEffect(() => {
        errorToast("You don't have permission to view this page");
    }, [errorToast]);

    return <Navigate to={`/dashboard`} replace />;
}
