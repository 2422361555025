import PrivateRoute from "pages/PrivateRoute";
import ConsignmentAcceptPage from "pages/Supplier/ConsignmentAcceptPage";
import VendorTransportDocumentsPage from "pages/transports/dvla/Vendor/VendorTransportDocumentsPage";
import ForgotPasswordPage from "pages/user/ForgotPasswordPage";
import LoginPage from "pages/user/LoginPage";
import ResetPasswordPage from "pages/user/ResetPasswordPage";
import UserVerifyPage from "pages/user/UserVerifyPage";
import RefurbReviewPage from "pages/vehicles/RefurbReviewPage";
import { Suspense, useCallback, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import DefaultLayoutRoute from "./DefaultLayoutRoute";

export default function AppRoutes() {
    const sleep = useCallback((ms: number) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }, []);

    const renderTrengo = useCallback(async () => {
        while (window.Trengo?.Api === undefined) {
            await sleep(500);
        }

        window.Trengo?.Api?.Widget?.render();
    }, [sleep]);

    useEffect(() => {
        renderTrengo();
    }, [renderTrengo]);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                <Route path="/reset-password/" element={<ResetPasswordPage />} />
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/user/verify/:userId" element={<UserVerifyPage />} />
                <Route path="/supplier/consignment/:consignmentId" element={<ConsignmentAcceptPage />} />
                <Route path="/vendor/transport/:transportId/dvla-upload" element={<VendorTransportDocumentsPage />} />
                <Route path="/vendor/refurb/:refurbId/review" element={<RefurbReviewPage />} />
                <Route path="/" element={<PrivateRoute />}>
                    <Route index element={<Navigate to="/dashboard" replace />} />
                    <Route path="/*" element={<DefaultLayoutRoute />} />
                </Route>
            </Routes>
        </Suspense>
    );
}

const Unauthorized = () => {
    return <h1>You don&apos;t have permission to see this</h1>;
};
