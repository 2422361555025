import * as Sentry from "@sentry/react";
import download from "downloadjs";
import { store } from "index";
import Cookies from "js-cookie";
import { HTTP_METHOD } from "lib/common/enums/http-method";
import { setSession } from "redux/SessionReducer";

export const _delete = async <T>(url: string): Promise<T> => {
    const apiUrl = `${import.meta.env.VITE_API_URL}${url}`;

    const finalApi = new URL(apiUrl);

    const options = await getOptions(HTTP_METHOD.DELETE);

    return fetch(finalApi.toString(), options).then(res => handleResponse<T>(res));
};

export const downloadFile = async (method: HTTP_METHOD, url: string, data?: any, returnFile = false) => {
    const apiUrl = `${import.meta.env.VITE_API_URL}${url}`;
    let finalApi = new URL(apiUrl);

    if (method === HTTP_METHOD.GET && data) {
        finalApi = applyQueryParams(finalApi, data);
    }

    const options = await getOptions(method, data);

    let filename = "File";

    return fetch(finalApi.toString(), options)
        .then(handleDownloadResponse)
        .then(res => {
            const disposition = res.headers.get("content-disposition");

            if (disposition) {
                filename = disposition.split("filename=")[1].split(";")[0].replaceAll('"', "");
            }

            return res.blob();
        })
        .then(blob => {
            download(blob, filename);
            if (returnFile) {
                return new File([blob], filename, { type: blob.type });
            }
        });
};

export const getFile = async (method: HTTP_METHOD, url: string, data?: any) => {
    const apiUrl = `${import.meta.env.VITE_API_URL}${url}`;
    let finalApi = new URL(apiUrl);

    if (method === HTTP_METHOD.GET && data) {
        finalApi = applyQueryParams(finalApi, data);
    }

    const options = await getOptions(method, data);

    let filename = "File";

    return fetch(finalApi.toString(), options)
        .then(handleDownloadResponse)
        .then(res => {
            const disposition = res.headers.get("content-disposition");
            if (disposition) {
                filename = disposition.split("filename=")[1].split(";")[0].replaceAll('"', "");
            }

            return res.blob();
        })
        .then(blob => {
            return new File([blob], filename, { type: blob.type });
        });
};

export const get = async <T>(url: string, data?: any): Promise<T> => {
    const apiUrl = `${import.meta.env.VITE_API_URL}${url}`;

    let finalApi = new URL(apiUrl);

    if (data) {
        finalApi = applyQueryParams(finalApi, data);
    }

    const options = await getOptions(HTTP_METHOD.GET, data);

    return fetch(finalApi.toString(), options).then(res => handleResponse<T>(res));
};

export const patch = async <T>(url: string, data?: any): Promise<T> => {
    const apiUrl = `${import.meta.env.VITE_API_URL}${url}`;

    const finalApi = new URL(apiUrl);

    const options = await getOptions(HTTP_METHOD.PATCH, data);

    return fetch(finalApi.toString(), options).then(res => handleResponse<T>(res));
};

export const post = async <T>(url: string, data?: any): Promise<T> => {
    const apiUrl = `${import.meta.env.VITE_API_URL}${url}`;

    const finalApi = new URL(apiUrl);

    const options = await getOptions(HTTP_METHOD.POST, data);

    return fetch(finalApi.toString(), options).then(res => handleResponse<T>(res));
};

export const put = async <T>(url: string, data: any): Promise<T> => {
    const apiUrl = `${import.meta.env.VITE_API_URL}${url}`;

    const finalApi = new URL(apiUrl);

    const options = await getOptions(HTTP_METHOD.PUT, data);

    return fetch(finalApi.toString(), options).then(res => handleResponse<T>(res));
};

const buildFormData = (formData, data, parentKey?) => {
    if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? "" : data;

        formData.append(parentKey, value);
    }
};

export const postFile = async <T>(url: string, fileToUpload: File, data?: any): Promise<T> => {
    const formData = new FormData();
    if (data) {
        buildFormData(formData, data);
    }
    formData.append("File", fileToUpload);

    const apiUrl = `${import.meta.env.VITE_API_URL}${url}`;

    const finalApi = new URL(apiUrl);

    const headers = {};

    const token = Cookies.get("g3_vamp");

    if (token) {
        headers["Authorization"] = `Bearer ${token}`;
    }

    const options = {
        headers: headers,
        method: HTTP_METHOD.POST,
    } as RequestInit;

    options.body = formData;

    return fetch(finalApi.toString(), options).then(res => handleResponse<T>(res));
};

export const postFiles = async <T>(url: string, filesToUpload: File[], data?: any): Promise<T> => {
    const formData = new FormData();
    if (data) {
        buildFormData(formData, data);
    }

    if (filesToUpload) {
        filesToUpload.forEach(f => {
            formData.append("files", f);
        });
    }

    const apiUrl = `${import.meta.env.VITE_API_URL}${url}`;

    const finalApi = new URL(apiUrl);

    const headers = {};

    const token = Cookies.get("g3_vamp");

    if (token) {
        headers["Authorization"] = `Bearer ${token}`;
    }

    const options = {
        headers: headers,
        method: HTTP_METHOD.POST,
    } as RequestInit;

    options.body = formData;

    return fetch(finalApi.toString(), options).then(res => handleResponse<T>(res));
};

function applyQueryParams(url: URL, data: any): URL {
    const urlWithQueryParams = new URL(url.toString());

    Object.keys(data).forEach(key => {
        if (data[key] !== undefined) {
            if (Array.isArray(data[key])) {
                data[key].forEach((_: any) => {
                    urlWithQueryParams.searchParams.append(key, _);
                });
            } else {
                urlWithQueryParams.searchParams.append(key, data[key]);
            }
        }
    });

    return urlWithQueryParams;
}

async function getHeaders(): Promise<Record<string, string>> {
    const headers = {
        "Content-Type": "application/json",
    };

    const token = Cookies.get("g3_vamp");

    if (token) {
        headers["Authorization"] = `Bearer ${token}`;
    }

    return headers;
}

async function getOptions(method: HTTP_METHOD, data?: any): Promise<RequestInit> {
    const options = {
        headers: await getHeaders(),
        method: method,
    } as RequestInit;

    if (hasNoBody(method, data)) {
        return options;
    }

    options.body = JSON.stringify(data);

    return options;
}

async function handleDownloadResponse(response: Response): Promise<Response> {
    const res = response.clone();

    return res
        .text()
        .then((text: string) => {
            if (!res.ok) {
                return Promise.reject(text);
            }

            return response;
        })
        .catch(reason => {
            return Promise.reject(reason);
        });
}

async function handleResponse<T>(response: Response): Promise<T> {
    if (!response.ok) {
        if (response.status === 401) {
            // Due to session validation, this forcibly logs the user out
            store.dispatch(setSession(undefined));

            Sentry.setUser(null);
            return Promise.reject("Please log in");
        }

        if (response.status === 403) {
            return Promise.reject("You don't have permission to do this");
        }

        return response.text().then(text => {
            if (text === "") {
                return Promise.reject(response.statusText);
            }
            return Promise.reject(text);
        });
    }

    return response.text().then((text: string) => {
        const data = text && JSON.parse(text);

        return data;
    });
}

function hasNoBody(method: HTTP_METHOD, data: unknown) {
    return !data || method === HTTP_METHOD.GET;
}

const api = {
    _delete,
    downloadFile,
    get,
    getFile,
    patch,
    post,
    put,
    postFile,
    postFiles,
};

export default api;
