import React, { useCallback } from "react";
import toast from "react-hot-toast";

export default function useVampToasts() {
    const errorToast = useCallback((content: any) => {
        const message = content?.message ?? content;

        try {
            toast.error(message, {
                position: "top-right",
                duration: 5000,
            });
        } catch {
            toast.error("An Error has Occurred", {
                position: "top-right",
                duration: 5000,
            });
        }
    }, []);

    const infoToast = useCallback((content: React.ReactNode) => {
        return toast(<div>{content}</div>, {
            position: "top-right",
            duration: 5000,
        });
    }, []);

    const successToast = useCallback(
        (content: React.ReactNode, disableAutoDismiss?: boolean) =>
            toast.success(<div>{content}</div>, {
                duration: 3000,
                position: "top-right",
            }),
        []
    );

    return {
        errorToast,
        infoToast,
        successToast,
    };
}
