import { CancelInquiryModel } from "@g3r-developers/vamp-types/dist";
import { InquiryReplyModel } from "@g3r-developers/vamp-types/dist/Inquiry/InquiryReplyModel";
import { PurchaseLedgerOpenInquiriesFilters } from "@g3r-developers/vamp-types/dist/Inquiry/PurchaseLedgerOpenInquiriesFilters";
import { CreateInvoiceInquiryModel } from "@g3r-developers/vamp-types/dist/accounts/invoice/CreateInvoiceInquiryModel";
import { InvoiceInquiryReturnModel } from "@g3r-developers/vamp-types/dist/accounts/invoice/InvoiceInquiryReturnModel";
import { DataTableResult } from "@g3r-developers/vamp-types/dist/data-table-result";
import { PagingModel } from "@g3r-developers/vamp-types/dist/paging-model";
import api from "../useApi";

const BaseUrl = `accounts/invoice/inquiry`;

const getById = (inquiryId: number): Promise<InvoiceInquiryReturnModel> => {
    return api.get(`${BaseUrl}/${inquiryId}`);
};

const getInquiriesForMe = (): Promise<InvoiceInquiryReturnModel[]> => {
    return api.get(`${BaseUrl}/me`);
};

const getOpen = async (
    model: PurchaseLedgerOpenInquiriesFilters
): Promise<DataTableResult<InvoiceInquiryReturnModel>> => {
    return api.get<DataTableResult<InvoiceInquiryReturnModel>>(`${BaseUrl}/open`, model);
};

const getReplied = async (model: PagingModel): Promise<DataTableResult<InvoiceInquiryReturnModel>> => {
    return api.get<DataTableResult<InvoiceInquiryReturnModel>>(`${BaseUrl}/replied`, model);
};

const replyToInquiry = async (inquiryId: number, model: InquiryReplyModel): Promise<void> => {
    return api.post(`${BaseUrl}/${inquiryId}/reply`, model);
};

const inquiry = async (invoiceId: number, model: CreateInvoiceInquiryModel): Promise<void> => {
    return api.post<void>(`accounts/invoice/${invoiceId}/inquiry`, model);
};

const resolveInquiry = async (inquiryId: number): Promise<void> => {
    return api.post<void>(`${BaseUrl}/${inquiryId}/resolve`);
};

const chaseInquiry = async (inquiryId: number): Promise<void> => {
    return api.post(`${BaseUrl}/${inquiryId}/chase`);
};

const cancel = async (inquiryId: number, model: CancelInquiryModel): Promise<void> =>
    api.post(`${BaseUrl}/${inquiryId}/cancel`, model);

const invoiceInquiryService = {
    cancel,
    chaseInquiry,
    getById,
    getInquiriesForMe,
    getOpen,
    getReplied,
    inquiry,
    replyToInquiry,
    resolveInquiry,
};

export default invoiceInquiryService;
