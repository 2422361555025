import { useCallback, useMemo } from "react";
import { useSession } from "./UseSession";

export default function useRoles() {
    const user = useSession();

    const userRoles = useMemo(() => user?.contact_groups ?? [], [user]);

    const hasRoles = useCallback((roles: string[]) => roles.some(role => userRoles.includes(role)), [userRoles]);

    return { hasRoles, userRoles };
}
