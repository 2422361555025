import "@g3r-developers/g3-common/dist/main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.scss";
import AppRoutes from "./routes/AppRoutes";

const App = () => {
    return (
        <Router>
            <AppRoutes />
            <Toaster />
        </Router>
    );
};
export default App;
