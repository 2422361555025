import { G3Card, G3FileDropzone } from "@g3r-developers/g3-common";
import { TransportDocumentRequestModel } from "@g3r-developers/vamp-types/dist/Transport/TransportDocumentRequestModel";
import useVampToasts from "hooks/UseVampToasts";
import { useCallback, useState } from "react";
import { Col, Row, Spinner } from "reactstrap";
import transportDvlaService from "services/Transport/TransportDvlaService";

const UploadedStatusName = "uploaded";

interface VendorUploadDocumentCardProps {
    loadRequests?: () => void;
    request: TransportDocumentRequestModel;
    token: string;
    transportId: number;
}

export default function VendorUploadDocumentCard({
    loadRequests,
    request,
    token,
    transportId,
}: VendorUploadDocumentCardProps) {
    const [uploading, setUploading] = useState<boolean>(false);

    const { errorToast, successToast } = useVampToasts();

    const uploadFile = useCallback(
        (file: File) => {
            setUploading(true);

            transportDvlaService
                .vendorUploadDvlaDocument(transportId, { doc_type_id: request.document_type.id, token: token }, file)
                .then(() => {
                    successToast("Document uploaded");
                    loadRequests();
                })
                .catch(errorToast)
                .finally(() => {
                    setUploading(false);
                });
        },
        [errorToast, loadRequests, request, successToast, token, transportId]
    );

    return (
        <G3Card title={request.document_type.title}>
            <Row>
                <Col>
                    {request.request_status.name !== UploadedStatusName ? (
                        uploading ? (
                            <div className="text-center">
                                <Spinner />
                            </div>
                        ) : (
                            <G3FileDropzone onFileAdded={(f: File) => uploadFile(f)} singleFile />
                        )
                    ) : (
                        <span>
                            <i className="fa fa-check me-2 text-success"></i>Already uploaded
                        </span>
                    )}
                </Col>
            </Row>
        </G3Card>
    );
}
