import { G3Input, IconButton, Loading } from "@g3r-developers/g3-common";
import { LoginModel } from "@g3r-developers/vamp-types/dist/User/LoginModel";
import * as Sentry from "@sentry/react";
import useQueryParams from "hooks/UseQueryParams";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FormGroup } from "reactstrap";
import { setSession } from "redux/SessionReducer";
import UserService from "services/user/UserService";
import { isEmpty } from "services/utils";

interface LoginFormErrors {
    login: string;
    email: string;
    password: string;
}

export default function LoginForm() {
    const [data, setData] = useState<LoginModel>({} as LoginModel);
    const [error, setError] = useState<LoginFormErrors>({} as LoginFormErrors);
    const [loading, setLoading] = useState<boolean>(false);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const queryParams = useQueryParams();

    const checkErrors = useCallback(() => {
        const e = {} as LoginFormErrors;

        if (!data.email) {
            e.email = "Please enter an email address";
        }
        if (!data.password) {
            e.password = "Please enter a password";
        }

        setError(e);
        return e;
    }, [data, setError]);

    const handleLogin = useCallback(() => {
        const e = checkErrors();

        if (!isEmpty(e)) {
            return;
        }

        setLoading(true);

        UserService.login(data)
            .then(async resp => {
                if (!resp.token) {
                    setError({ login: "Email or Password incorrect" } as LoginFormErrors);
                    return;
                }

                Sentry.setUser({
                    id: resp.contact.contact_id.toString(),
                    email: resp.contact.email,
                });

                window.Trengo.contact_data = {
                    email: resp.contact.email,
                    name: resp.contact.full_name,
                };

                if (window.Trengo?.Api?.Widget) {
                    window.Trengo.Api.Widget.render();
                }

                dispatch(setSession(resp));
                const redirectUrl = queryParams.get("redirectUrl");
                navigate(redirectUrl ?? "/dashboard");
            })
            .catch(e => setError({ login: e?.message ?? e } as LoginFormErrors))
            .finally(() => setLoading(false));
    }, [checkErrors, data, dispatch, queryParams, navigate]);

    return (
        <>
            <FormGroup className="mb-3 w-50" style={{ margin: "0 auto" }}>
                <G3Input
                    label="Email"
                    type="email"
                    error={error.email}
                    onChange={(e: string) =>
                        setData(r => {
                            return { ...r, email: e };
                        })
                    }
                    value={data.email}
                />
            </FormGroup>
            <FormGroup className="mb-3 w-50" style={{ margin: "0 auto" }}>
                <G3Input
                    label="Password"
                    type="password"
                    error={error.password}
                    onChange={(e: string) =>
                        setData(r => {
                            return { ...r, password: e };
                        })
                    }
                    onKeyPress={e => {
                        if (e.key === "Enter") {
                            handleLogin();
                        }
                    }}
                    value={data.password}
                />
            </FormGroup>

            {!!error.login && <div className="mb-3 text-danger text-center text-bold">{error.login}</div>}

            <FormGroup className="mb-3 w-50" style={{ margin: "0 auto" }}>
                {loading ? <Loading /> : <IconButton block variant="primary" onClick={handleLogin} text="Login" />}

                <div className="mt-4 text-center">
                    <Link to="/forgot-password" className="text-muted">
                        Forgot Password?
                    </Link>
                </div>
            </FormGroup>
        </>
    );
}
