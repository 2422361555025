import { useEffect } from "react";
import { updateTitle } from "services/utils";

const Page = (props: any) => {
    const PageComponent = props.component;

    useEffect(() => {
        if (props.title) {
            updateTitle(props.title);
        }
    }, [props, props.title, props.titleChanged]);

    return (
        <>
            <PageComponent user={props.user} titleChanged={props.titleChanged} {...props} />
        </>
    );
};

export default Page;
