import { IconButton, showconfirmmodal } from "@g3r-developers/g3-common";
import useVampToasts from "hooks/UseVampToasts";
import ProfilePic from "lib/common/profile-pic";
import { useCallback, useMemo, useState } from "react";
import { ListGroupItem } from "reactstrap";
import noteService from "services/note/NoteService";
import { NoteModel } from "types/note/note-model";
import { NoteDisplayCleanText } from "../NoteDisplayCleanText/NoteDisplayCleanText";

interface NoteDisplayProps {
    currentContactId?: number;
    management?: boolean;
    note: NoteModel;
    reload?: () => void;
}

export const NoteDisplay = ({ currentContactId, management, note, reload }: NoteDisplayProps) => {
    const { errorToast, successToast } = useVampToasts();

    const [loading, setLoading] = useState<boolean>(false);

    const handleDelete = useCallback(async () => {
        const confirm = await showconfirmmodal("Delete Note", "Delete this note?");

        if (!confirm) {
            return;
        }

        try {
            setLoading(true);
            await noteService.deleteNote(note.note_id);
            reload && reload();
            successToast("Note removed");
        } catch (e) {
            errorToast(e);
        } finally {
            setLoading(false);
        }
    }, [errorToast, reload, showconfirmmodal, successToast]);

    const allowDelete = useMemo(() => {
        return management || (!!currentContactId && currentContactId === note.created_contact?.contact_id);
    }, [currentContactId, management, note]);

    return (
        <ListGroupItem className="activity-item d-flex" key={`note-${note.note_id}`}>
            <div className="me-3">
                <ProfilePic media={note.created_contact?.profile_picture} width={50} />
            </div>
            <div className="mt-2 ms-2 w-100">
                <div className="mb-0 text-muted font-sm">
                    {note.created_contact?.full_name ?? "Inactive User"} @ {note.added_string}
                </div>
                <h6 style={{ whiteSpace: "pre-line" }}>
                    <NoteDisplayCleanText note_text={note.note_text} />
                </h6>
            </div>
            {allowDelete && (
                <div>
                    <IconButton
                        className="mt-2 ms-1"
                        faIconString={loading ? "fas fa-spinner fa-spin" : "fas fa-trash-alt"}
                        disabled={loading}
                        onClick={handleDelete}
                        tooltip={"Delete"}
                        variant={"secondary"}
                        size="sm"
                    />
                </div>
            )}
        </ListGroupItem>
    );
};
