import { useTypedSearchParams } from "hooks/UseTypedSearchParams";
import { ContactGroupNames } from "lib/common/enums/ContactGroups";
import { Roles } from "lib/common/enums/Roles";
import AuthorisedRoute from "pages/AuthorisedRoute";
import InquiryDetailsPage from "pages/invoice/InquiryDetailsPage";
import { lazy, Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { Route, Routes } from "react-router-dom";

const Accounts = lazy(() => import("../pages/accounts/AccountsSwitch"));
const Blog = lazy(() => import("../lib/blog/ViewBlog"));
const BuyerServices = lazy(() => import("../pages/BuyerServices/BuyerServicesSwitch"));
const Admin = lazy(() => import("../pages/admin/AdminSwitch"));
const Companies = lazy(() => import("../pages/companies/CompanySwitch"));
const Compliance = lazy(() => import("../pages/compliance/ComplianceSwitch"));
const Dashboard = lazy(() => import("lib/dashboard/Dashboard"));
const Fleet = lazy(() => import("../pages/fleet/FleetSwitch"));
const Grievances = lazy(() => import("../pages/complaints/GrievancesSwitch"));
const Lot = lazy(() => import("../pages/lots/LotSwitch"));
const Python = lazy(() => import("../pages/python/PythonSwitch"));
const Reception = lazy(() => import("../pages/reception/ReceptionSwitch"));
const Reporting = lazy(() => import("../pages/Reporting/ReportingSwitch"));
const Sales = lazy(() => import("../pages/sales/SaleSwitch"));
const SystemConfigPage = lazy(() => import("../pages/SystemConfig/SystemConfigPage"));
const Transport = lazy(() => import("../pages/transports/TransportSwitch"));
const User = lazy(() => import("../pages/user/UserSwitch"));
const Vehicle = lazy(() => import("../pages/vehicles/VehiclesSwitch"));
const Yard = lazy(() => import("../pages/yard/YardSwitch"));
const Roadmap = lazy(() => import("../lib/dashboard/Roadmap"));
const Help = lazy(() => import("../lib/dashboard/Help"));
const Nav = lazy(() => import("../pages/Nav"));
const AutomateSwitch = lazy(() => import("../pages/Automate/AutomateSwitch"));

interface DefaultLayoutRouteSearchParams {
    hideNav: string;
}

const DefaultLayoutRoute = () => {
    const [sb, setSidebar] = useState<string>("full");

    const { searchParams } = useTypedSearchParams<DefaultLayoutRouteSearchParams>();

    const handleResize = useCallback(() => {
        const width = window.innerWidth > 0 ? window.innerWidth : 0;

        switch (sb) {
            case "full": {
                if (width < 1170) {
                    setSidebar("mini-sidebar");
                } else {
                    setSidebar("full");
                }
                break;
            }
            default: {
                break;
            }
        }
    }, [sb]);

    const showSidebar = useMemo(() => {
        if (searchParams.get("hideNav") === "true") {
            return false;
        }

        return true;
    }, [searchParams]);

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, [handleResize]);

    return (
        <div
            id="main-wrapper"
            className={showSidebar ? undefined : "sidebar-hidden"}
            data-theme="light"
            data-layout="vertical"
            data-navbarbg="skin6"
            data-sidebartype={sb}
            data-sidebar-position="fixed"
            data-header-position="fixed"
            data-boxed-layout="full"
        >
            <Nav />

            <div className={"d-block page-wrapper"}>
                <div className="container-fluid">
                    <Suspense fallback={<div>Loading...</div>}>
                        <Routes>
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/vehicle/*" element={<Vehicle />} />
                            <Route path="/lot/*" element={<Lot />} />
                            <Route path="/python/*" element={<Python />} />
                            <Route path="/blog/:blogPostId" element={<Blog />} />
                            <Route path="/roadmap" element={<Roadmap />} />
                            <Route path="/help" element={<Help />} />
                            <Route
                                element={
                                    <AuthorisedRoute
                                        roles={[
                                            ContactGroupNames.STAFF_TRANSPORT,
                                            ContactGroupNames.TRANSPORT_MANAGEMENT,
                                            ContactGroupNames.STAFF_MANAGEMENT,
                                        ]}
                                    />
                                }
                            >
                                <Route path="/transport/*" element={<Transport />} />
                            </Route>
                            <Route path="/company/*" element={<Companies />} />
                            <Route path="/sale/*" element={<Sales />} />
                            <Route path="/automate/*" element={<AutomateSwitch />} />
                            <Route
                                element={
                                    <AuthorisedRoute
                                        roles={[
                                            Roles.StaffManagement,
                                            Roles.StaffAccounts,
                                            ContactGroupNames.STAFF_ACCOUNTS,
                                        ]}
                                    />
                                }
                            >
                                <Route path="/accounts/*" element={<Accounts />} />
                            </Route>
                            <Route
                                element={
                                    <AuthorisedRoute
                                        roles={[ContactGroupNames.STAFF_MANAGEMENT, ContactGroupNames.STAFF_YARD]}
                                    />
                                }
                            >
                                <Route path="/yard/*" element={<Yard />} />
                            </Route>

                            <Route element={<AuthorisedRoute roles={[ContactGroupNames.STAFF_MANAGEMENT]} />}>
                                <Route path="/admin/*" element={<Admin />} />
                            </Route>

                            <Route
                                element={
                                    <AuthorisedRoute
                                        roles={[ContactGroupNames.STAFF_MANAGEMENT, ContactGroupNames.STAFF_FRONT_DESK]}
                                    />
                                }
                            >
                                <Route path="/reception/*" element={<Reception />} />
                            </Route>
                            <Route
                                element={
                                    <AuthorisedRoute
                                        roles={[
                                            ContactGroupNames.STAFF_MANAGEMENT,
                                            ContactGroupNames.STAFF_BUYER_SERVICES,
                                        ]}
                                    />
                                }
                            >
                                <Route path="/grievances/*" element={<Grievances />} />
                            </Route>
                            <Route path="/user/*" element={<User />} />
                            <Route
                                element={
                                    <AuthorisedRoute
                                        roles={[
                                            ContactGroupNames.STAFF_MANAGEMENT,
                                            ContactGroupNames.STAFF_TRANSPORT,
                                            ContactGroupNames.TRANSPORT_MANAGEMENT,
                                        ]}
                                    />
                                }
                            >
                                <Route path="/fleet/*" element={<Fleet />} />
                            </Route>
                            <Route
                                element={
                                    <AuthorisedRoute
                                        roles={[ContactGroupNames.STAFF_MANAGEMENT, ContactGroupNames.STAFF_COMPLIANCE]}
                                    />
                                }
                            >
                                <Route path="/compliance/*" element={<Compliance />} />
                            </Route>
                            <Route element={<AuthorisedRoute roles={[ContactGroupNames.STAFF_MANAGEMENT]} />}>
                                <Route path="/system-config" element={<SystemConfigPage />} />
                            </Route>
                            <Route
                                element={
                                    <AuthorisedRoute
                                        roles={[
                                            ContactGroupNames.STAFF_MANAGEMENT,
                                            ContactGroupNames.STAFF_BUYER_SERVICES,
                                        ]}
                                    />
                                }
                            >
                                <Route path="/buyer-services/*" element={<BuyerServices />} />
                            </Route>

                            <Route path="/reporting/*" element={<Reporting />} />

                            <Route path="/inquiry/:inquiryId" element={<InquiryDetailsPage />} />
                            <Route path="*" element={<NotFoundComponent />} />
                        </Routes>
                    </Suspense>
                </div>
            </div>
        </div>
    );
};

const NotFoundComponent = () => {
    return <h1>Computer Says No (404)</h1>;
};

export default DefaultLayoutRoute;
