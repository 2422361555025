import { useValidateSession } from "hooks/UseValidateSession";
import { useEffect, useMemo } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

function PrivateRoute() {
    const { pathname, search } = useLocation();
    const redirectUrl = useMemo(() => encodeURIComponent(`${pathname}${search}`), [pathname, search]);

    const authenticated = useValidateSession();

    useEffect(() => {
        if (!authenticated) {
            return;
        }
        if (!window.Trengo.contact_data) {
            return;
        }
    }, [authenticated]);

    return authenticated ? <Outlet /> : <Navigate to={`/login?redirectUrl=${redirectUrl}`} replace />;
}

export default PrivateRoute;
