import { VampLoginResponse } from "@g3r-developers/vamp-types/dist";
import { LoginModel } from "@g3r-developers/vamp-types/dist/User/LoginModel";
import { UserRegisterReturnModel } from "@g3r-developers/vamp-types/dist/User/UserRegisterReturnModel";
import { UserVerifyModel } from "@g3r-developers/vamp-types/dist/User/UserVerifyModel";
import { store } from "index";
import { setSession } from "redux/SessionReducer";
import api from "services/useApi";

const getUser = () => {
    return store.getState().session.user;
};

const getRoles = async (): Promise<string[]> => {
    const user = store.getState().session.user;

    return Promise.resolve(user.contact_groups ?? []);
};

const register = (contactId: number): Promise<UserRegisterReturnModel> => {
    return api.post(`user/register/${contactId}`);
};

const login = (data: LoginModel): Promise<VampLoginResponse> => {
    return api.post<VampLoginResponse>(`user/login`, data);
};

const logout = (): void => {
    const user = store.getState().session?.user;
    if (!user) {
        return;
    }

    api.post(`user/logout`);

    store.dispatch(setSession(undefined));
};

const verify = (model: UserVerifyModel): Promise<UserVerifyModel> => {
    return api.post(`user/verify`, model);
};

const resendInviteEmail = (contactId: number): Promise<boolean> => {
    return api.get(`user/resend-invite/${contactId}`);
};

const UserService = {
    getRoles,
    getUser,
    login,
    logout,
    register,
    resendInviteEmail,
    verify,
};

export default UserService;
