import useRoles from "hooks/UseRoles";
import { useSession } from "hooks/UseSession";
import { ContactGroupNames } from "lib/common/enums/ContactGroups";
import { useMemo } from "react";
import { ListGroup } from "reactstrap";
import { NoteModel } from "types/note/note-model";
import { NoteDisplay } from "./NoteDisplay/NoteDisplay";

interface NoteListProps {
    notes: NoteModel[];
    reload?: () => void;
}

export default function NoteList({ notes, reload }: NoteListProps) {
    const user = useSession();
    const { hasRoles } = useRoles();

    const isManagement = useMemo(() => hasRoles([ContactGroupNames.STAFF_MANAGEMENT]), [hasRoles]);

    return (
        <ListGroup className={"mt-3 note-list"}>
            {notes.map(n => (
                <NoteDisplay
                    key={n.note_id}
                    note={n}
                    reload={reload}
                    currentContactId={user?.contact?.contact_id}
                    management={isManagement}
                />
            ))}
        </ListGroup>
    );
}
