import { G3Card, G3TextArea, IconButton, InfoTag, Loading } from "@g3r-developers/g3-common";
import { InvoiceInquiryReturnModel } from "@g3r-developers/vamp-types/dist/accounts/invoice/InvoiceInquiryReturnModel";
import { InquiryReplyModel } from "@g3r-developers/vamp-types/dist/Inquiry/InquiryReplyModel";
import useVampToasts from "hooks/UseVampToasts";
import NoteList from "lib/note/list";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import invoiceInquiryService from "services/accounts/InvoiceInquiryService";

export default function InquiryView() {
    const { successToast, errorToast } = useVampToasts();
    const [inquiry, setInquiry] = useState<InvoiceInquiryReturnModel>(undefined);
    const [responseNote, setResponseNote] = useState<string>("");
    const [savingNote, setSavingNote] = useState<boolean>(false);

    const { inquiryId: inquiryIdParam } = useParams<{ inquiryId?: string }>();
    const inquiryId = useMemo(() => Number(inquiryIdParam), [inquiryIdParam]);

    const loadInquiry = useCallback(() => {
        invoiceInquiryService.getById(inquiryId).then(setInquiry).catch();
    }, [inquiryId]);

    useEffect(() => {
        loadInquiry();
    }, [loadInquiry, inquiryId]);

    const saved = useCallback(() => {
        successToast("Response has been sent");
        setResponseNote("");
        loadInquiry();
    }, [loadInquiry, successToast]);

    const handleSave = useCallback(() => {
        setSavingNote(true);
        invoiceInquiryService
            .replyToInquiry(inquiryId, {
                note: responseNote,
            } as InquiryReplyModel)
            .then(saved)
            .catch(errorToast)
            .finally(() => {
                setSavingNote(false);
            });
    }, [errorToast, inquiryId, responseNote, saved]);

    const handleNoteChanged = useCallback((newVal: string) => {
        setResponseNote(newVal);
    }, []);

    return (
        <>
            {!inquiry && <Loading />}
            {inquiry && (
                <>
                    <G3Card title={`Inquiry Details`}>
                        <Row>
                            <Col md="4">
                                <InfoTag title="Invoice Number" value={inquiry.invoice.number} />
                            </Col>
                            <Col md="4">
                                <InfoTag title="Invoice From" value={inquiry.invoice.supplier_company.company_name} />
                            </Col>
                            <Col md="4">
                                <InfoTag title="Invoice Date" value={inquiry.invoice.invoice_date_string} />
                            </Col>
                            <Col md="4">
                                <InfoTag title="Invoice Amount" value={inquiry.invoice.amount_inc_vat_string} />
                            </Col>
                            <Col md="4">
                                <InfoTag title="Inquiry Opened By" value={inquiry.created_contact.full_name} />
                            </Col>
                            <Col md="4">
                                <InfoTag title="Date Opened" value={inquiry.added_string} />
                            </Col>
                            <Col md="4">
                                <InfoTag title="Inquiry Status" value={inquiry.inquiry_status.title} />
                            </Col>
                            <Col md="4">
                                <InfoTag title="Assigned To" value={inquiry.assigned_contact.full_name} />
                            </Col>
                            {inquiry.invoice.generated_invoice_url && (
                                <Col md="12">
                                    <IconButton
                                        className="w-100"
                                        targetString="_blank"
                                        text="View Invoice"
                                        externalUrl={inquiry.invoice.generated_invoice_url}
                                        variant={"secondary"}
                                    />
                                </Col>
                            )}
                        </Row>
                    </G3Card>

                    <G3Card title="Notes">
                        <NoteList notes={inquiry.notes} />

                        <G3TextArea label="Response" onChange={handleNoteChanged} value={responseNote} />

                        <IconButton
                            disabled={savingNote}
                            variant="secondary"
                            onClick={handleSave}
                            faIconString="fa fa-reply"
                            text="Reply"
                            className="w-100"
                        />
                    </G3Card>
                </>
            )}
        </>
    );
}
