import RefurbReview from "lib/vehicle/Refurbishment/RefurbReview/RefurbReview";

export default function RefurbReviewPage() {
    return (
        <div className="h-100">
            <div className={"m-5"}>
                <div className={"align-items-center flex-nowrap"}>
                    <div className="mb-3 w-50 text-center mx-auto my-0">
                        <img src="https://media.g3r.co.uk/logo.png" alt="G3 Vehicle Auctions" />
                    </div>
                    <RefurbReview />
                </div>
            </div>
        </div>
    );
}
