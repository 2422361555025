import { DataTableResult } from "@g3r-developers/vamp-types/dist/data-table-result";
import { AddInvoiceNote } from "@g3r-developers/vamp-types/dist/note/AddInvoiceNote";
import { AddNoteRequest } from "@g3r-developers/vamp-types/dist/note/AddNoteRequest";
import { UserNoteReturnModel } from "@g3r-developers/vamp-types/dist/note/UserNoteReturnModel";
import { NoteModel } from "@g3r-developers/vamp-types/dist/note/note-model";
import { PagingModel } from "@g3r-developers/vamp-types/dist/paging-model";
import api from "services/useApi";

const getVehicleNotes = (vehicleId: number): Promise<NoteModel[]> => {
    return api.get<NoteModel[]>(`note/vehicle/${vehicleId}`);
};

const createVehicleNote = (vehicleId: number, data: AddNoteRequest): Promise<NoteModel> => {
    return api.post<NoteModel>(`note/vehicle/${vehicleId}`, data);
};

const deleteNote = async (noteId: number) => {
    return api._delete(`note/${noteId}`);
};

const getTransportNotes = (transportId: number): Promise<NoteModel[]> => {
    return api.get<NoteModel[]>(`note/transport/${transportId}`);
};

const createTransportNote = (transportId: number, data: AddNoteRequest): Promise<NoteModel> => {
    return api.post<NoteModel>(`note/transport/${transportId}`, data);
};

const getSaleNotes = (saleId: number): Promise<NoteModel[]> => {
    return api.get<NoteModel[]>(`note/sale/${saleId}`);
};

const createSaleNote = (saleId: number, note: string): Promise<NoteModel> => {
    const data = {
        note: note,
    } as AddNoteRequest;

    return api.post<NoteModel>(`note/sale/${saleId}`, data);
};

const getCompanyNotes = (companyId: number): Promise<NoteModel[]> => {
    return api.get<NoteModel[]>(`note/company/${companyId}`);
};

const createCompanyNote = (companyId: number, note: string): Promise<NoteModel> => {
    const data = {
        note: note,
    } as AddNoteRequest;

    return api.post<NoteModel>(`note/company/${companyId}`, data);
};

const createBuyerApplicationReviewNote = (buyerApplicationReviewId: number, note: string): Promise<NoteModel> => {
    const data = {
        note: note,
    } as AddNoteRequest;

    return api.post<NoteModel>(`note/buyerapplicationreview/${buyerApplicationReviewId}`, data);
};

const getGrievanceNotes = (grievanceId: number): Promise<NoteModel[]> => {
    return api.get<NoteModel[]>(`note/grievance/${grievanceId}`);
};

const createGrievanceNote = (grievanceId: number, data: AddNoteRequest): Promise<NoteModel> => {
    return api.post<NoteModel>(`note/grievance/${grievanceId}`, data);
};

const getPaymentNotes = (paymentId: number): Promise<NoteModel[]> => {
    return api.get<NoteModel[]>(`note/payment/${paymentId}`);
};

const getInvoiceNotes = (invoiceId: number): Promise<NoteModel[]> => {
    return api.get<NoteModel[]>(`note/invoice/${invoiceId}`);
};

const createPaymentNote = (paymentId: number, request: AddNoteRequest): Promise<NoteModel> => {
    return api.post<NoteModel>(`note/payment/${paymentId}`, request);
};

const createMultiplePaymentNote = (request: AddNoteRequest) => {
    return api.post(`note/payments`, request);
};

const createMultipleInvoiceNote = (request: AddInvoiceNote) => {
    return api.post(`note/email/invoice`, request);
};

const getUserNotes = async (model: PagingModel): Promise<DataTableResult<UserNoteReturnModel>> => {
    return api.get<DataTableResult<UserNoteReturnModel>>(`note/user`, model);
};

const noteService = {
    createMultipleInvoiceNote,
    createBuyerApplicationReviewNote,
    createCompanyNote,
    createGrievanceNote,
    createMultiplePaymentNote,
    createPaymentNote,
    createSaleNote,
    createTransportNote,
    createVehicleNote,
    deleteNote,
    getCompanyNotes,
    getGrievanceNotes,
    getPaymentNotes,
    getSaleNotes,
    getTransportNotes,
    getUserNotes,
    getVehicleNotes,
    getInvoiceNotes,
};

export default noteService;
