import { VampLoginResponse } from "@g3r-developers/vamp-types/dist";
import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { DateTime } from "luxon";

const VAMP_COOKIE_NAME = "g3_vamp";
const VAMP_USER_STORAGE_KEY = "vamp_user";

export interface SessionState {
    user: VampLoginResponse | undefined;
}

const getInitial = () => {
    const storedUser = localStorage.getItem(VAMP_USER_STORAGE_KEY);

    if (!storedUser || storedUser === "undefined" || storedUser === "null") {
        return undefined;
    }

    const user: VampLoginResponse = JSON.parse(storedUser);

    if (!user) {
        return undefined;
    }

    const expiry = DateTime.fromISO(user.expiry);

    if (expiry <= DateTime.now()) {
        localStorage.removeItem(VAMP_USER_STORAGE_KEY);

        return undefined;
    }

    return user;
};

const initialState = {
    user: getInitial(),
} as SessionState;

export const SessionSlice = createSlice({
    name: "session",
    initialState,
    reducers: {
        setSession: (state, action) => {
            const user = action.payload as VampLoginResponse;

            state.user = user;

            if (action.payload === undefined) {
                localStorage.removeItem(VAMP_USER_STORAGE_KEY);
                Cookies.remove(VAMP_COOKIE_NAME);
            } else {
                localStorage.setItem(VAMP_USER_STORAGE_KEY, JSON.stringify(user));
                Cookies.set(VAMP_COOKIE_NAME, user.token, { expires: DateTime.fromISO(user.expiry).toJSDate() });
            }
        },
    },
});

// Action creators are generated for each case reducer function
export const { setSession } = SessionSlice.actions;

export default SessionSlice.reducer;
